@import "../../colors/colors.scss";

.couvreur {
  &__container {
    width: 60%;
    margin: 0 auto;
    display: flex;
    padding: 20px;
  }
  &__image {
    background-image: url("../../images/background/couvreur-60.jpeg");
    width: 100%;
    height: 400px;
    background-size: cover;
    background-repeat: no-repeat;
  }
  &__second_image_container {
    display: flex;
    justify-content: flex-end;
  }
  &__second_image_content {
    width: 90%;
    box-shadow: -50px 50px 5px $primary;
  }
  &__second_image {
    padding: 10px;
    width: 100%;
    height: 100%;
    box-shadow: -2px 2px 5px lightgray;
  }

  @media screen and (max-width: 1500px) {
    &__container {
      width: 80%;
      padding: 20px;
    }
  }

  @media screen and (max-width: 1100px) {
    &__container {
      width: 90%;
      padding: 20px;
    }
  }

  @media screen and (max-width: 800px) {
    &__container {
      width: 100%;
      padding: 20px;
    }
  }

  @media screen and (max-width: 500px) {
    &__container {
      width: 100%;
      flex-direction: column;
    }
    &__second_image_content {
      margin-bottom: 30px;
    }
  }
}
