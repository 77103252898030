@import "../../colors/colors.scss";

.our_services {
  &__main_title_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__item_title {
    color: $second;
    font-family: "DMSansBold";
  }
  &__item_description_container {
    font-family: "DMSans";
    color: grey;
    border-bottom: 2px solid $primary;
  }
  &__item_description {
    font-family: "DMSans";
    color: grey;
    font-size: 16px;
  }
  &__read_more {
    font-family: "DMSansBold";
    color: $second;
    margin: 20px 0;
  }
  &__carousel_small {
    display: none;
  }

  @media screen and (max-width: 500px) {
    &__carousel {
      display: none;
    }
    &__carousel_small {
      display: block;
    }
  }
}
