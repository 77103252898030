.home_container {
  padding: 50px 20px;
  width: 60%;
  margin: 0 auto;
  position: relative;
  display: flex;
}

.home_carousel_slide_1 {
  height: 400px;
  object-fit: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 5px;
  background-image: url("../../images/background/couvreur-presentation-1.jpeg");
}

.home_carousel_slide_2 {
  height: 400px;
  object-fit: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 5px;
  background-image: url("../../images/background/couvreur-presentation-2.jpeg");
}

.home_carousel_container {
  padding: 50px 20px;
  width: 60%;
  margin: 0 auto;
}

@media screen and (max-width: 1500px) {
  .home_container {
    width: 80%;
  }
  .home_carousel_container {
    width: 80%;
  }
}

@media screen and (max-width: 1100px) {
  .home_container {
    width: 90%;
  }
  .home_carousel_container {
    width: 90%;
  }
}

@media screen and (max-width: 800px) {
  .home_container {
    width: 100%;
  }
  .home_carousel_container {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .home_container {
    padding: 20px;
    width: 100%;
  }

  .home_carousel_container {
    padding: 20px;
    width: 100%;
  }
}
