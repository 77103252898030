@import "../../colors/colors.scss";

.carCard {
  width: 100%;
  &__image {
    height: 200px;
    background-position: center;
  }
  &__dotContainer {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__dot {
    border-radius: 100%;
    height: 5px;
    width: 5px;
    background-color: red;
    margin: 0 5px;
  }
}
