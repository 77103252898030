@import "../../../../colors/colors.scss";

.carousel {
  &__first_slide {
    background-image: url("../../../../images/background/couverture-60.jpeg");
    width: 100%;
    height: 600px;
    background-size: cover;
    background-repeat: no-repeat;
  }
  &__second_slide {
    background-image: url("../../../../images/background/ravalement-60.jpeg");
    width: 100%;
    height: 600px;
    background-size: cover;
    background-repeat: no-repeat;
  }
  &__third_slide {
    background-image: url("../../../../images/background/maconnerie-60.jpeg");
    width: 100%;
    height: 600px;
    background-size: cover;
    background-repeat: no-repeat;
  }
  &__slide_over {
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.2);
  }
  &__content_slide {
    position: absolute;
    z-index: 1 !important;
    height: 100%;
    width: 700px !important;
    margin-left: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__main_title {
    color: $white;
    font-size: 50px;
    padding: 0;
    margin: 30px 0;
    line-height: 1.1;
    font-family: "DMSansBold";
  }
  &__text {
    color: $white;
    font-family: "DMSansBold";
    font-size: 20px;
  }
  &__call_button_container {
    display: flex;
    margin-top: 40px;
  }
  &__call_button {
    background-color: $primary;
    border: 2px solid $white;
    width: 200px;
    margin-right: 20px;
    display: flex;
    justify-content: space-around;
    padding: 5px;
    cursor: pointer;
    font-size: 20px;
    font-family: "DMSansBold";
  }
  &__call_button_text {
    color: $white !important;
    text-decoration: none !important;
  }
  &__call_button_text:hover {
    color: $white !important;
  }

  @media screen and (max-width: 1100px) {
    &__content_slide {
      width: 1024px !important;
      margin-left: 0px;
      padding: 10px;
    }
    &__main_title {
      font-size: 20px;
    }
    &__text {
      font-size: 16px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }
  }

  @media screen and (max-width: 1100px) {
    &__content_slide {
      width: 750px !important;
    }
  }

  @media screen and (max-width: 500px) {
    &__content_slide {
      width: 425px !important;
      margin-left: 0px;
      padding: 10px;
    }
    &__main_title {
      font-size: 20px;
    }
    &__text {
      font-size: 16px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }
    &__call_button_container {
      flex-direction: column;
      margin-top: 20px;
    }
    &__call_button {
      margin-top: 10px;
    }
  }
}
