@import "../../colors/colors.scss";

.pose_velux {
  &__container {
    width: 60%;
    margin: 0 auto;
    display: flex;
  }
  &__image {
    background-image: url("../../images/background/pose-de-velux-60.jpeg");
    width: 100%;
    height: 400px;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
