@import "../../../../colors/colors.scss";

.navbar_mobile_menu {
  background-color: rgba(0, 0, 0, 0) !important;
  color: $second !important;
  border-bottom: 0 !important;
  padding: 10px 300px;
}
.navbar_mobile .ant-menu-title-content:hover {
  color: $primary;
}
.navbar_mobile .ant-menu-item-selected {
  color: $primary !important;
}

.navbar_mobile .ant-menu-title-content {
  font-family: "DMSansBold" !important;
  font-size: 14px !important;
  text-transform: uppercase;
  color: $second;
}
