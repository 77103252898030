@import "../../colors/colors.scss";

.text_description {
  &__container {
    flex: 1;
  }
  &__title {
    font-weight: bold;
    color: $primary;
  }
  &__main_title {
    color: $second;
    font-family: "DMSansBold";
    font-size: 40px;
  }
  &__divider {
    height: 2px;
    background-color: $primary;
  }
  &__text_container {
    margin-top: 20px;
  }
  &__text_content {
    font-family: "DMSans";
    color: grey;
    font-size: 20px;
    margin-bottom: 20px;
  }
}
