@import "../../colors/colors.scss";

.ravalement_facade {
  &__container {
    width: 60%;
    margin: 0 auto;
    display: flex;
  }
  &__image {
    background-image: url("../../images/background/ravalement-de-facade-60.png");
    width: 100%;
    height: 400px;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
