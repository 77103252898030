@import "../../../../colors/colors.scss";

.navbar_menu {
  background-color: rgba(0, 0, 0, 0) !important;
  color: $second !important;
  border-bottom: 0 !important;
  padding: 10px 300px;
}
.navbar .ant-menu-title-content:hover {
  color: $primary;
}

.navbar .ant-menu-title-content {
  font-family: "DMSansBold" !important;
  font-size: 14px !important;
  text-transform: uppercase;
}

@media screen and (max-width: 1500px) {
  .navbar_menu {
    padding: 10px 100px;
  }
}

@media screen and (max-width: 1100px) {
  .navbar_menu {
    padding: 10px 20px;
  }

  .navbar .ant-menu-title-content {
    font-size: 12px !important;
  }
}
