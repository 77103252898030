@import "../../colors/colors.scss";

.title_bar_primary {
  &__title {
    color: $primary;
    text-transform: uppercase;
    font-family: "DMSansBold" !important;
    font-size: 20px;
    display: flex;
    align-items: center;
  }

  &__title_bar {
    height: 2px;
    background-color: $primary;
    width: 40px;
    margin-right: 15px;
  }
}
