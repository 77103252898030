$orange: #fb5a2b;
$blue: #4062aa;

$white: #fff;
$black: #000000;
$bold_grey: #494949;
$regular_grey: #898989;
$regular_grey_opacity: rgba(137, 137, 137, 0.7);
$light_grey: #b4b4b4;
$extra_light_gray: "#eeeeee";
$lightGrey: lightgrey;

$primary: $orange;
$second: $blue;
$third: $lightGrey;
