@import "../../colors/colors.scss";

.header {
  z-index: 10;
  position: relative;
  width: 100%;
  &__top_bar {
    background-color: $primary;
    padding: 10px 300px;
    color: $white;
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    font-weight: bold;
  }
  &__contact_us {
    display: flex;
    align-items: center;
    font-family: "DMSansBold";
  }
  &__phone_container {
    display: flex;
  }
  &__phone_first {
    margin-right: 30px;
    display: flex;
    font-family: "DMSansBold";
  }
  &__phone_second {
    display: flex;
    font-family: "DMSansBold";
  }
  &__middle_bar {
    display: flex;
    justify-content: space-between;
    flex: 1;
    margin-left: 100px;
  }
  &__bottom_bar {
    padding: 10px 300px;
    background-color: #c0c5c9;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__logo {
    height: 100px;
  }
  &__icon {
    font-size: 30px;
    color: $primary;
  }
  &__icon_title {
    color: $second;
    font-size: 18px;
    font-family: "DMSansBold";
    margin-bottom: 5px;
  }
  &__icon_value {
    color: $primary;
    font-size: 18px;
    font-family: "DMSansBold";
  }
  &__drawer_button_container {
    display: none;
    position: absolute;
    right: 20px;
    top: 45px;
  }
  &__drawer_button {
    padding: 5px 10px;
    border-radius: 5px;
    background-color: $second;
    color: $white;
  }

  @media screen and (max-width: 1500px) {
    &__top_bar {
      padding: 10px 100px;
    }
    &__bottom_bar {
      padding: 10px 100px;
    }
    &__middle_bar {
      margin-left: 20px;
      font-size: 16px;
    }
  }

  @media screen and (max-width: 1100px) {
    &__top_bar {
      padding: 10px 20px;
      font-size: 16px;
    }
    &__bottom_bar {
      padding: 10px 20px;
    }
    &__icon_value {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 800px) {
    &__navbar_container {
      display: none;
    }
    &__middle_bar {
      flex-direction: column;
      margin-left: 150px;
    }
    &__drawer_button_container {
      display: block;
    }
  }

  @media screen and (max-width: 500px) {
    &__top_bar {
      padding: 10px 10px;
      flex-direction: column;
      align-items: center;
    }
    &__contact_us {
      margin-bottom: 5px;
    }
    &__phone_container {
      flex-direction: column;
    }
    &__phone_first {
      margin-bottom: 5px;
    }
    &__bottom_bar {
      padding: 10px 10px;
      flex-direction: column;
      align-items: center;
    }
    &__logo {
      margin-bottom: 20px;
    }
    &__middle_bar {
      flex-direction: column;
      margin-left: 0px;
    }
  }
}
