@import "../../colors/colors.scss";

.footer {
  &__container {
    object-fit: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("../../images/background/construction-dispositif-pinel.jpeg");
  }
  &__hover {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    padding: 50px 0;
  }
  &__content {
    width: 60%;
    margin: 0 auto;
  }
  &__description_text {
    color: $white;
    font-family: "DMSansBold";
    font-size: 16px;
    line-height: 25px;
  }
  &__bottom_text_container {
    margin-top: 0px;
  }
  &__title {
    color: $white;
    font-family: "DMSansBold";
  }
  &__divider {
    height: 3px;
    background-color: $primary;
    width: 60px;
  }
  &__bottom_text {
    margin-top: 30px;
    line-height: 25px;
    color: $white;
  }
  &__link_container {
    margin-top: 30px;
  }
  &__link {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 20px 0;
    border-bottom: 0.5px solid grey;
    color: $white;
    font-family: "DMSansBold";
  }

  @media screen and (max-width: 1500px) {
    &__content {
      width: 80%;
      padding: 10px;
    }
  }

  @media screen and (max-width: 1100px) {
    &__content {
      width: 90%;
      padding: 10px;
    }
  }

  @media screen and (max-width: 800px) {
    &__content {
      width: 100%;
      padding: 10px;
    }
  }

  @media screen and (max-width: 500px) {
    &__hover {
      padding: 10px;
    }
    &__content {
      width: 100%;
      padding: 10px;
    }
  }
}
