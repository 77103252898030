@import "../../colors/colors.scss";

.contact_form {
  &__container {
    width: 300px;
    margin-right: 40px;
  }
  &__header {
    height: 80px;
    padding: 0 10px;
    width: 300px;
    background-color: $primary;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__header_text {
    color: $white;
  }
  &__content {
    width: 300px;
    padding: 20px 10px;
    background-color: $lightGrey;
  }
  &__submit_button {
    margin-top: 40px;
  }

  @media screen and (max-width: 500px) {
    &__container {
      padding: 0px;
      width: 100%;
      margin-right: 0;
      margin-bottom: 30px;
    }
    &__header {
      width: 100%;
    }
    &__content {
      width: 100%;
    }
  }
}
