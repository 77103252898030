@import "../../colors/colors.scss";

.contact {
  &__container {
    width: 60%;
    margin: 0 auto;
    padding-top: 50px;
  }
  &__image {
    background-image: url("../../images/background/contact.jpeg");
    width: 100%;
    height: 400px;
    background-size: cover;
    background-repeat: no-repeat;
  }
  &__informations_main_container {
    margin-top: 60px;
  }
  &__informations_container {
    padding: 20px;
    border: 0.5px solid lightgray;
  }
  &__divider {
    height: 0.5px;
    background-color: lightgray;
    margin: 20px 0;
  }
  &__informations_title_container {
    display: flex;
    align-items: center;
  }
  &__icons_container {
    height: 40px;
    width: 40px;
    background-color: $primary;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-right: 10px;
  }
  &__icons {
    color: $white;
    font-size: 30px;
  }
}
