@import "../../colors/colors.scss";

.contact_us {
  &__container {
    margin-top: 50px;
    background-image: url("../../images/background/chiffres.jpeg");
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__form {
    padding: 50px;
    width: 60%;
    background-color: $primary;
  }
  &__form .ant-select-selector {
    height: 43px !important;
  }
  &__form .ant-select-selection-item {
    align-items: center;
    display: flex;
  }
  &__form .ant-form-item-explain-error {
    color: $white;
    font-family: "DMSansBold";
    text-transform: uppercase;
    margin-bottom: 10px;
    margin-top: 5px;
  }
  &__informations {
    padding: 0 10px;
  }
  &__input {
    padding: 10px;
  }
  &__title {
    margin-left: 10px;
    font-size: 30px;
    color: $white;
    font-family: "DMSansBold";
  }
  &__subtitle {
    margin-bottom: 30px;
    color: $white;
    font-family: "DMSansBold";
  }
  &__description {
    margin-top: 20px;
    margin-left: 10px;
    font-size: 18px;
    line-height: 25px;
    color: $white;
    font-family: "DMSansBold";
  }
  &__contact_informations {
    display: flex;
  }
  &__icon_title {
    font-size: 20px;
    margin-right: 10px;
    color: $white;
  }
  &__contact_text {
    color: $white;
    font-family: "DMSansBold";
  }
  &__send_button {
    border-color: $primary;
    background-color: $second;
    color: $white;
  }
  &__send_button:hover {
    border: 1px solid $white;
    background-color: $second !important;
    color: $white !important;
  }

  @media screen and (max-width: 1500px) {
    &__form {
      width: 80%;
      padding: 20px;
    }
  }

  @media screen and (max-width: 800px) {
    &__form {
      width: 100%;
      padding: 10px;
      margin: 10px;
    }
    &__contact_informations {
      margin-top: 5px;
    }
  }

  @media screen and (max-width: 500px) {
    &__container {
      height: 100%;
      padding: 10px;
    }
    &__form {
      width: 100%;
      padding: 10px;
    }
  }
}
